export const Roles = {
  Landlord: "Landlord",
  Tenant: "Tenant",
  TradesPerson: "Tradesperson",
  Liaison: "Liaison"
};
export const commentRoles = {
  Landlord: "Landlord",
  Tenant: "Tenant",
  Tradesperson: "Tradesperson",
  Liaison: "Property Liaison"
};
export const TradespersonTypes = Object.freeze({
  Landlord: "Landlord",
  Liaison: "Liaison",
  Tradesperson: "Tradesperson"
});
export const FilterTicketStatus = {
  New: "New",
  In_Progress: "In Progress",
  Closed: "Closed"
};
export const SnackbarSeverity = {
  warning: "warning",
  info: "info",
  success: "success",
  dange: "danger"
};

export const Store = {
  isLoggedIn: "isLoggedIn",
  role: "role",
  userId: "userId",
  token: "token",
  isProfileComplete: "isProfileComplete",
  email: "email",
  isEmailVerified: "isEmailVerified",
  businessName: "businessName",
  createdAt: "createdAt",
  updatedAt: "updatedAt",
  contact: "contact",
  profileImage: "profileImage",
  liaisonFirstLogin: "liaisonFirstLogin",
  landlordBusinessName: "landlordBusinessName",
  expireSoon: "expireSoon"
};

export const IntialUserState = {
  userData: {
    contact: "",
    email: "",
    firstName: "",
    jwtToken: "",
    lastName: "",
    role: "",
    isLoggedIn: false
  },
  signupPayload: {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobile: "",
    businessName: "",
    postCode: "",
    address: ""
  }
};

export const TicketStatus = {
  New: "New",
  Open: "Open",
  OpenAssigned: "OpenAssigned",
  Closed: "Closed",
  Reopen: "Reopen"
};

export const TicketSeverity = {
  Normal: "Normal",
  // Emergency: "Emergency",
  Urgent: "Urgent"
};

export const PropertyExtras = [
  {
    internet: "High Speed Internet"
  },
  {
    utility: "Separate Utility"
  },
  {
    parking: "Off Road Parking"
  },
  {
    oneMinWalk: "1 Minute Walk to Campus"
  },
  {
    twoMinWalk: "2 Minute Walk to Campus"
  },
  {
    fiveMinWalk: "5 Minute Walk to Campus"
  },
  {
    livingRoom: "Separate Living Room"
  },
  {
    garden: "Garden Maintenance"
  },
  {
    bicycle: "Bicycle Shed"
  },
  {
    livingArea: "Open-plan Living Area"
  },
  {
    refurbished: "Newly Refurbished"
  }
];

export const ActivityTimeLine = Object.freeze({
  Today: "Today",
  Yesterday: "Yesterday",
  This_Week: "This Week",
  Last_Week: "Last Week"
});

export const ActivityTimeLineSortOrder = Object.freeze({
  Today: 1,
  Yesterday: 2,
  "This Week": 3,
  "Last Week": 4
});

export const ageProfileChartXLabels = Object.freeze({
  "< 3 days": "< 3 days",
  "4-7 days": "4-7 days",
  "8-21 days": "8-21 days",
  "> 21 days": "> 21 days"
});
export const ageProfileStackLabels = Object.freeze({
  New: "New",
  InProgress: "InProgress"
});

export const ageProfileStackLabelsColors = Object.freeze({
  New: "#2876A7",
  InProgress: "#83C6EC"
});

export const inProgressLabel = Object.freeze({
  Check_Completion: "Check Completion",
  Tradeperson_Assigned: "Tradesperson Assigned",
  Job_Booked: "Job Booked",
  Validate_job: "Validate Job"
});

export const inProgressLabelTradesperson = Object.freeze({
  Job_Booked: "Job Booked",
  Confirm_Completion: "Confirm Completion",
  Pending_Closure: "Pending Closure"
});

export const inProgressLabelSortOrder = Object.freeze({
  "Check Completion": 4,
  "Tradesperson Assigned": 2,
  "Job Booked": 3,
  "Validate Job": 1
});
export const inProgressLabelSortOrderTradesperson = Object.freeze({
  "Job Booked": 1,
  "Confirm Completion": 2,
  "Pending Closure": 3
});

export const inProgressLabelColors = Object.freeze({
  "Validate Job": "#034671",
  "Tradesperson Assigned": "#479ACE",
  "Job Booked": "#9CD0EE",
  "Check Completion": "#D2EFFF"
});
export const inProgressLabelTextColorColors = Object.freeze({
  "Validate Job": "white",
  "Tradesperson Assigned": "white",
  "Job Booked": "black",
  "Check Completion": "black"
});

export const inProgressLabelColorsTradesperson = Object.freeze({
  "Job Booked": "#034671",
  "Confirm Completion": "#479ACE",
  "Pending Closure": "#9CD0EE"
});
